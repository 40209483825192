export default class HoverInfo {
  constructor(element) {
    this.element = element;

    this.listContact = document.querySelectorAll('[data-contact]');
    this.contactImg = document.querySelector('.contact__img');

    this.init();
  }

  init() {
    this.listContact.forEach((element) => {
      element.addEventListener('mouseover', () => {
        this.contactImg.src = 'assets/images/contact/' + element.getAttribute('data-contact') + '.svg';
        this.contactImg.alt = element.getAttribute('data-contact');
      });
    });
  }
}
